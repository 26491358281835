import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceProvider {

  public FAB_OPENED = 'fab_opened';

  constructor() {
  }

  public setStorage(key, value) {
    if (key && value) {
      if (value instanceof Object) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
        return;
      }
      window.sessionStorage.setItem(key, value);
    }
  }

  public getStorage(key) {
    return key && window.sessionStorage.getItem(key);
  }

  public removeStorage(key) {
    return window.sessionStorage.removeItem(key);
  }
}

