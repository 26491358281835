import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService} from '../api/api.service';

@Injectable({
  providedIn: 'root'
})

export class LinkpreviewService {

  API_KEY = '5c520a6fd0dfdff30aea8ce9e94f7cfa629ac242e6363';
  LINK_PREVIEW_ENDPOINT = 'https://api.linkpreview.net?key=' + this.API_KEY + '&q=';

  constructor(
      private http: HttpClient
  ) { }

  getInfo(url: string) {
    ApiService.spinnerIncrement();
    return this.http.get(this.LINK_PREVIEW_ENDPOINT + url).toPromise().then(data => {
      ApiService.spinnerDecrement();
      return data;
    }).catch(error => {
      ApiService.spinnerDecrement();
      throw new Error(error);
    });
  }

  getFromURL(url) {
    ApiService.spinnerIncrement();
    return this.http.get(url, {
      responseType: 'blob'
    }).toPromise().then(data => {
      ApiService.spinnerDecrement();
      return data;
    }).catch(error => {
      ApiService.spinnerDecrement();
      return error;
    });
  }
}
